"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _buffer = require("buffer");

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var verallia = verallia || {};

verallia.FileLoader = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: "initData",
        value: function initData() {
            this.empty = true;
            this.onComplete;
            this.onError;
            this.onProgress;
            this.num_loader = 0;
            this.id_loader = 0;
            this.pr;
            this.loader;
            this.pool = {};
        }
    }, {
        key: "addFile",
        value: function addFile(id, url, data, type, ext, no_cache, data_type, token) {
            if (!this.loader) this.loader = [];

            if (!no_cache && no_cache != true) url = url + "?" + verallia.StringUtils.generateUID();

            this.empty = false;

            //ext = ext || url.substring(url.length-3, url.length);
            var temp_url = url.split('?')[0];
            ext = ext || temp_url.split('.').pop();
            if (!type) type = "get";

            this.loader[this.id_loader] = { id: id, url: url, ext: ext, type: type, data: data, data_type: data_type, token: token };
            this.id_loader++;
            this.num_loader++;
        }
    }, {
        key: "get",
        value: function get(id) {
            return this.pool[id];
        }
    }, {
        key: "dispose",
        value: function dispose(id) {
            this.pool[id] = null;
            delete this.pool[id];
        }
    }, {
        key: "load",
        value: function load(_onComplete, _onError, _onProgress) {
            if (this.empty == true) {
                _onComplete();
                return;
            }

            this.onComplete = _onComplete;
            this.onError = _onError;
            this.onProgress = _onProgress;

            this.id_loader = 0;
            this.pr = 0;

            this.loadFile();
        }
    }, {
        key: "loadFile",
        value: function loadFile() {
            var ldr = this.loader[this.id_loader];

            if (!ldr) {
                this.resetLoader();
                return;
            }

            if (ldr.type == "head") {
                $.ajax({ type: 'HEAD', url: ldr.url, success: this.completeHead.bind(this), error: this.completeHead.bind(this) });
                return;
            }

            if (ldr.ext == "html") $.ajax({ type: "get", url: ldr.url, dataType: "text", complete: this.complete.bind(this), error: this.error.bind(this), xhrFields: { onprogress: this.progress.bind(this) } });
            if (ldr.ext == "txt") $.ajax({ type: "get", url: ldr.url, dataType: "text", complete: this.complete.bind(this), error: this.error.bind(this), xhrFields: { onprogress: this.progress.bind(this) } });
            if (ldr.ext == "obj") $.ajax({ type: "get", url: ldr.url, dataType: "text", complete: this.complete.bind(this), error: this.error.bind(this), xhrFields: { onprogress: this.progress.bind(this) } });
            if (ldr.ext == "css") $.ajax({ type: "get", url: ldr.url, dataType: "text/css", complete: this.complete.bind(this), error: this.error.bind(this), xhrFields: { onprogress: this.progress.bind(this) } });
            if (ldr.ext == "xml") $.ajax({ type: "get", url: ldr.url, dataType: "xml", success: this.complete.bind(this), error: this.error.bind(this), xhrFields: { onprogress: this.progress.bind(this) } });
            if (ldr.ext == "csv") $.ajax({ type: "get", url: ldr.url, dataType: "text", success: this.complete.bind(this), error: this.error.bind(this), xhrFields: { onprogress: this.progress.bind(this) } });
            if (ldr.ext == "js") $.ajax({ type: "get", url: ldr.url, dataType: "script", success: this.completeScript.bind(this), error: this.error.bind(this), xhrFields: { onprogress: this.progress.bind(this) } });
            if (ldr.ext == "json") $.ajax({ type: "get", url: ldr.url, dataType: "json", success: this.completeScript.bind(this), error: this.error.bind(this), xhrFields: { onprogress: this.progress.bind(this) } });
            if (ldr.ext == "service_meteor") {
                var req = {};
                req.type = ldr.type;
                req.contentType = false;
                req.data = ldr.data;
                req.url = ldr.url;

                if (ldr.token) {
                    trace("adding token to request: " + ldr.token);
                    req.beforeSend = function (xhr) {
                        xhr.setRequestHeader("Authorization", "Bearer " + ldr.token);
                    };
                }

                if (ldr.data_type) req.contentType = ldr.data_type;

                req.cache = false;
                req.processData = false;
                req.error = this.error.bind(this);
                req.success = this.complete.bind(this);
                $.ajax(req);
            }
            if (ldr.ext == "service") $.ajax({ type: ldr.type, url: ldr.url, data: ldr.data, success: this.complete.bind(this), error: this.error.bind(this) });
            if (ldr.ext == "jpg" || ldr.ext == "png" || ldr.ext == "gif") {
                ldr.image = new Image();
                ldr.image.name = ldr.id;
                ldr.image.onload = this.completeImage.bind(this);
                ldr.image.onerror = this.errorImage.bind(this);
                ldr.image.crossOrigin = "Anonymous";
                ldr.image.src = ldr.url;
            }
            if (ldr.ext == "ttf" || ldr.ext == "otf") {
                ldr.font = new Font();
                ldr.font.name = ldr.id;
                ldr.font.onload = this.completeFont.bind(this);
                ldr.font.onerror = this.errorFont.bind(this);
                ldr.font.src = ldr.url;
            }
        }
    }, {
        key: "completeHead",
        value: function completeHead(message, text, response) {
            var ldr = this.loader[this.id_loader];
            this.pool[ldr.id] = { status: response.status, message: message, text: text, response: response };

            if (response.status == 200) {
                try {
                    this.pool[ldr.id]["last-modified"] = new Date(response.getResponseHeader("last-modified")).getTime();
                } catch (e) {}
            }

            this.checkLoader();
        }
    }, {
        key: "completeScript",
        value: function completeScript(datas) {
            var ldr = this.loader[this.id_loader];
            this.pool[ldr.id] = datas;

            this.checkLoader();
        }
    }, {
        key: "complete",
        value: function complete(datas) {
            if (!this.loader) {
                return;
            }
            var ldr = this.loader[this.id_loader];
            //console.log("complete: "+ l.id+" : "+ l.url+" : "+id_loader);

            if (ldr.ext == "html") this.pool[ldr.id] = datas.responseText;
            if (ldr.ext == "txt") this.pool[ldr.id] = datas.responseText;
            if (ldr.ext == "obj") this.pool[ldr.id] = datas.responseText;
            if (ldr.ext == "xml") this.pool[ldr.id] = $(datas);
            if (ldr.ext == "csv") this.pool[ldr.id] = datas;
            if (ldr.ext == "service_meteor") this.pool[ldr.id] = datas;
            if (ldr.ext == "service") this.pool[ldr.id] = datas;
            if (ldr.ext == "json") this.pool[ldr.id] = datas;
            if (ldr.ext == "css") {
                this.pool[ldr.id] = $(datas);
                $("head").append($("<link rel='stylesheet' media='all' href='" + ldr.url + "' type='text/css'>"));
            }
            this.checkLoader();
        }
    }, {
        key: "completeImage",
        value: function completeImage() {
            if (!this.loader) {
                return;
            }
            var ldr = this.loader[this.id_loader];
            //if(l.ext == "jpg" || l.ext == "png" || l.ext == "gif") PoolManager.add(l.id, l.image);
            if (ldr.ext == "jpg" || ldr.ext == "png" || ldr.ext == "gif") this.pool[ldr.id] = ldr.image;

            if (this.onProgress) this.onProgress((this.pr + 1) / this.num_loader);
            this.checkLoader();
        }
    }, {
        key: "completeFont",
        value: function completeFont() {
            if (!this.loader) {
                return;
            }
            var ldr = this.loader[this.id_loader];
            //if(l.ext == "jpg" || l.ext == "png" || l.ext == "gif") PoolManager.add(l.id, l.image);
            if (ldr.ext == "ttf" || ldr.ext == "oft") this.pool[ldr.id] = ldr.image;

            if (this.onProgress) this.onProgress((this.pr + 1) / this.num_loader);
            this.checkLoader();
        }
    }, {
        key: "checkLoader",
        value: function checkLoader() {
            //console.log("complete: "+ id_loader);
            this.pr++;
            this.id_loader++;

            //onProgress(pr);
            //console.log(id_loader+" : " +num_loader);

            if (this.id_loader == this.num_loader) {
                this.resetLoader();
            } else {
                this.loadFile();
            }
        }
    }, {
        key: "resetLoader",
        value: function resetLoader() {
            this.loader = null;
            this.id_loader = 0;
            this.num_loader = 0;
            this.onError = null;
            this.onProgress = null;

            this.empty = true;
            this.onComplete();
        }
    }, {
        key: "error",
        value: function error(e, statusText, erreur) {
            this.loader = null;
            this.id_loader = 0;
            this.num_loader = 0;

            this.empty = true;
            this.onError(erreur);

            //console.log("error: "+erreur);
        }
    }, {
        key: "errorImage",
        value: function errorImage() {
            this.onError("erreur");
        }
    }, {
        key: "errorFont",
        value: function errorFont() {
            this.onError("erreur");
        }
    }, {
        key: "progress",
        value: function progress(e) {
            if (e.lengthComputable) {
                if (this.onProgress != null) {
                    this.onProgress((this.pr + e.loaded / e.total) / this.num_loader);
                }
            }
        }
    }]);

    return _class;
}();
verallia.FileLoader.initData();