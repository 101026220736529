'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

verallia.SSO = function () {
    //=============================================================================
    //=========================== Initialization ==================================
    //=============================================================================


    function _class() {
        _classCallCheck(this, _class);

        //=============================================================================
        //=========================== Static Constants ================================
        //=============================================================================

        /* Local Storage Parameters */
        this.oConfig = window.SSO_CONFIG;
        this.oModel;
        this.SSO_PREFIX = 'SSO';
        this.KEY_CAS_ACCESS_TOKEN = 'CAS_ACCESS_TOKEN';
        /* Login Steps */
        this.LOGGED_OUT = 'LOGGED_OUT';
        this.NO_ACCESS_TOKEN = 'NO_ACCESS_TOKEN';
        this.ACCESS_TOKEN = 'ACCESS_TOKEN';
        this.LOGGED_IN = 'LOGGED_IN';

        // get access to local storage
        //this.oLocalStorage = new LocalStorage(this.SSO_PREFIX);

        // initialize
        this._initInternalModel();
        this.user_data;

        // create dialogs
        //this.oLoginDlg  = new DialogSSOLogin(this);
        //this.oLogoutDlg = new DialogSSOLogout(this);
    }

    //=================================================================================================================


    _createClass(_class, [{
        key: 'destroy',
        value: function destroy() {

            this.getModel().destroy();

            //this.oLoginDlg.destroy();
            //delete this.oLoginDlg;

            //            this.oLogoutDlg.destroy();
            //            delete this.oLogoutDlg;

            verallia.LocalStorageUtils.deleteAllKeys();
        }

        //=============================================================================
        //=========================== IAuthentication Impl ============================
        //=============================================================================


    }, {
        key: 'login',
        value: function login() {

            var oDef;
            var sAccessToken = verallia.LocalStorageUtils.getKey(this.SSO_PREFIX + this.KEY_CAS_ACCESS_TOKEN);

            if (sAccessToken) {

                // an access token is stored: try to use it
                oDef = this._getUserProfile(sAccessToken).then(function (oResponse) {
                    // here, filter CAS' response: it could be a valid response with an error content
                    if (!oResponse || oResponse.error) {
                        return jQuery.Deferred().reject(null, 'INVALID', oResponse && oResponse.error);
                    }
                    return oResponse;
                }).then(function (oUserData) {

                    // here, access token is valid, and user name has been retrieved successfully
                    this.sAccessToken = sAccessToken;
                    this.sUsername = oUserData.id;
                    this.user_data = oUserData;

                    // update internal model
                    this._setSSOLoginStep(this.LOGGED_IN);

                    trace('user logged in', null, 'sge.launchpad.service.SSO');
                }.bind(this), function (oXHR, sError, sMsg) {

                    // access token has expired: restart process without stored token to get a new one
                    // TODO
                    if (sError === 'INVALID') {
                        trace('invalid or expired access token', null, 'sge.launchpad.service.SSO');
                        this._forget();
                        return this.login();
                    }

                    // unexpected error, close dialog
                    //this.oLoginDlg.close();
                    $(window).trigger("SSO_DIALOG_CLOSE");

                    // propagate error
                    trace('cannot get user profile', null, 'sge.launchpad.service.SSO');
                    var oErrDef = jQuery.Deferred();
                    return oErrDef.reject.apply(oErrDef, arguments);
                }.bind(this)).done(function () {
                    //this.oLoginDlg.close();
                    $(window).trigger("SSO_DIALOG_CLOSE");
                }.bind(this));
            } else {

                // no stored access token: get one!
                oDef = this._getAccessToken().then(function (sNewAccessToken) {

                    // store new access token and restart process
                    trace('got new access token', null, 'sge.launchpad.service.SSO');
                    verallia.LocalStorageUtils.setKey(this.SSO_PREFIX + this.KEY_CAS_ACCESS_TOKEN, sNewAccessToken);
                    return this.login();
                }.bind(this), function () {

                    // unexpected error, close dialog and forget all data
                    $(window).trigger("SSO_DIALOG_CLOSE");
                    this._forget();

                    // propagate error
                    trace('cannot get an access token', null, 'sge.launchpad.service.SSO');
                    var oErrDef = jQuery.Deferred();
                    oErrDef.reject.apply(oErrDef, arguments);
                    return oErrDef;
                }.bind(this));
            }

            return oDef.promise();
        }

        //=================================================================================================================

    }, {
        key: 'logout',
        value: function logout() {

            // configure and open logout dialog
            //            this.oLogoutDlg.open();

            // actually log out from CAS server
            return this._asyncLogout().done(function () {
                trace('user logged out', null, 'sge.launchpad.service.SSO');
            }).fail(function () {
                trace('failed to log user out', null, 'sge.launchpad.service.SSO');
            }).always(function () {
                this._forget();
                this._setSSOLoginStep(this.LOGGED_OUT);
            }.bind(this));
        }

        //=================================================================================================================

    }, {
        key: 'getLoggedUser',
        value: function getLoggedUser() {
            return this.user_data;
        }

        //=================================================================================================================

    }, {
        key: 'isLogged',
        value: function isLogged() {
            return !!this.sUsername;
        }

        //=============================================================================
        //=========================== IRequestProvider Impl ===========================
        //=============================================================================


    }, {
        key: 'getRequest',
        value: function getRequest(oAjaxParams) {
            this._prepareRequest(oAjaxParams);
            return jQuery.ajax(oAjaxParams);
        }

        //=================================================================================================================

    }, {
        key: 'getSyncRequest',
        value: function getSyncRequest(oAjaxParams) {
            this._prepareRequest(oAjaxParams);
            oAjaxParams.async = false;
            return jQuery.ajax(oAjaxParams);
        }

        //=================================================================================================================

    }, {
        key: 'getRequestSettings',
        value: function getRequestSettings(oAjaxParams) {
            this._prepareRequest(oAjaxParams);
            return oAjaxParams;
        }

        //=============================================================================
        //=========================== Internal Helpers ================================
        //=============================================================================


    }, {
        key: '_getAccessToken',
        value: function _getAccessToken() {

            var oDef = jQuery.Deferred();

            // define temporary global function to allow communication from embedded iframe to current window
            window.SSOCallback = function (oResult) {

                // return the access token if provided
                if (oResult.accessToken) {
                    oDef.resolve(oResult.accessToken);
                } else {
                    oDef.reject(oResult);
                }
            }.bind(this);

            // open login dialog which embeds an iframe linked to CAS server
            this._setSSOLoginStep(this.NO_ACCESS_TOKEN);
            $(window).trigger("SSO_DIALOG_OPEN");

            return oDef.always(function () {
                // clean up global function
                delete window.SSOCallback;
            });
        }

        //=================================================================================================================

    }, {
        key: '_getUserProfile',
        value: function _getUserProfile(sAccessTokenCandidate) {

            var oDef = jQuery.Deferred(),
                oConfig = this.oConfig.SSO;

            // ensure login dialog is open
            this._setSSOLoginStep(this.ACCESS_TOKEN);
            $(window).trigger("SSO_DIALOG_OPEN");

            // DEV
            //            jQuery.sap.delayedCall(1500, oDef, function() { this.resolve({ id : 'J1811360' }); });
            //            jQuery.sap.delayedCall(3000, oDef, function() { this.reject('re-nope'); });
            //            return oDef;


            jQuery.ajax({
                url: oConfig.CAS_XDOMAIN_URI + oConfig.CAS_USER_PROFILE_URI,
                method: 'get',
                data: { access_token: sAccessTokenCandidate }
            }).done(oDef.resolve.bind(oDef)).fail(oDef.reject.bind(oDef));

            return oDef;
        }

        //=================================================================================================================

    }, {
        key: '_prepareRequest',
        value: function _prepareRequest(oAjaxParams) {

            // ensure cache is disabled for GET requests
            if (!oAjaxParams.method || oAjaxParams.method.toLowerCase() === 'get') {
                oAjaxParams.cache = false;
            }

            // define callback if authorization failed
            oAjaxParams.statusCode = {
                // access token has expired: erase everything and log in again
                401: function () {
                    this._forget().login();
                }.bind(this)
            };

            // define SSO-specific header
            if (this.sAccessToken && !(oAjaxParams.headers && oAjaxParams.headers.Authorization)) {
                oAjaxParams.headers = jQuery.extend({ Authorization: 'Bearer ' + this.sAccessToken }, oAjaxParams.headers);
            }

            return oAjaxParams;
        }

        //=================================================================================================================

    }, {
        key: '_forget',
        value: function _forget() {

            // forget token and logged user
            delete this.sAccessToken;
            delete this.sUsername;

            // remove any value from local storage
            verallia.LocalStorageUtils.deleteKey(this.SSO_PREFIX + this.KEY_CAS_ACCESS_TOKEN);

            return this; // to allow method chaining
        }

        //=================================================================================================================

    }, {
        key: '_asyncLogout',
        value: function _asyncLogout() {

            var oDef = jQuery.Deferred(),
                oConfig = this.oConfig.SSO,
                oF;

            // create an invisible iframe pointing to CAS' logout page
            oF = document.createElement('iframe');
            oF.style.display = 'none';
            oF.sandbox = "allow-same-origin allow-forms allow-scripts";
            oF.src = oConfig.CAS_XDOMAIN_URI + oConfig.CAS_LOGOUT_URI, oF.onerror = oDef.reject;
            oF.onload = oDef.resolve;
            document.body.appendChild(oF);

            return oDef.always(function () {
                document.body.removeChild(oF);
            }).promise();
        }

        //=================================================================================================================

    }, {
        key: '_initInternalModel',
        value: function _initInternalModel() {
            var bIsPhone = window.device.mobile(),
                oConfig = this.oConfig.SSO;

            this.oModel = {
                autologin: this._hasAutologin(),
                //                loginURI  : 'sso.html', // DEV
                loginURI: oConfig.CAS_XDOMAIN_URI + oConfig.CAS_AUTH_URI + '?client_id=' + oConfig.CAS_CLIENT_ID + '&redirect_uri=' + location.origin + location.pathname + 'sso.html',
                UI: {
                    dialogLoginHeight: '551px',
                    dialogLoginWidth: bIsPhone ? '100%' : '738px',
                    dialogLogoutHeight: '551px',
                    dialogLogoutWidth: bIsPhone ? '100%' : '738px'
                    //                    dialogLoginHeight  : bIsPhone ? '516px' : '500px',
                    //                    dialogLoginWidth   : bIsPhone ? '100%'    : '500px',
                    //                    dialogLogoutHeight : '277px',
                    //                    dialogLogoutWidth  : bIsPhone ? '100%'    : '500px',
                }

                /*this.setModel(new JSONModel({
                    autologin : this._hasAutologin(),
                    //                loginURI  : 'sso.html', // DEV
                    loginURI  : oConfig.CAS_XDOMAIN_URI + oConfig.CAS_AUTH_URI + '?client_id=' + oConfig.CAS_CLIENT_ID + '&redirect_uri=' + location.origin + location.pathname + 'sso.html',
                    UI : {
                        dialogLoginHeight  : '551px',
                        dialogLoginWidth   : bIsPhone ? '100%'    : '738px',
                        dialogLogoutHeight : '551px',
                        dialogLogoutWidth  : bIsPhone ? '100%'    : '738px',
                        //                    dialogLoginHeight  : bIsPhone ? '516px' : '500px',
                        //                    dialogLoginWidth   : bIsPhone ? '100%'    : '500px',
                        //                    dialogLogoutHeight : '277px',
                        //                    dialogLogoutWidth  : bIsPhone ? '100%'    : '500px',
                    },
                }));*/

            };this._setSSOLoginStep(this.LOGGED_OUT);

            return this; // to allow method chaining
        }

        //=================================================================================================================

    }, {
        key: '_hasAutologin',
        value: function _hasAutologin() {
            return false;
            // current CAS asks to manually confirm the connection --> should always display the iframe
            //            return !!sap.ui.Device.system.desktop && (sap.ui.Device.browser.name === sap.ui.Device.browser.BROWSER.INTERNET_EXPLORER);
        }

        //=================================================================================================================

    }, {
        key: '_setSSOLoginStep',
        value: function _setSSOLoginStep(sStep) {

            //var oModel = this.getModel();
            var oModel = this.oModel;
            return;

            switch (sStep) {

                case this.LOGGED_IN:
                    oModel.setProperty('/isLogged', true);
                    oModel.setProperty('/isNoLogged', false);
                // no "break" here to execute following code
                case this.ACCESS_TOKEN:
                    oModel.setProperty('/hasNoAccessToken', false);
                    oModel.setProperty('/autologinOrHasAccessToken', true);
                    break;

                case this.LOGGED_OUT:
                    oModel.setProperty('/isLogged', false);
                    oModel.setProperty('/isNoLogged', true);
                // no "break" here to execute following code
                case this.NO_ACCESS_TOKEN:
                    oModel.setProperty('/hasNoAccessToken', true);
                    oModel.setProperty('/autologinOrHasAccessToken', this._hasAutologin());
                    break;
            }

            return this; // to allow method chaining
        }
    }]);

    return _class;
}();