"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var verallia = verallia || {};

verallia.MouseEvent = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: "init",
        value: function init() {
            this.CLICK = "click";
            this.MOUSE_MOVE = "mousemove";
            this.MOUSE_DOWN = "mousedown";
            this.MOUSE_OUT = "mouseleave";
            this.MOUSE_OVER = "mouseenter";
            this.SCROLL = "scroll";
        }
    }]);

    return _class;
}();
verallia.MouseEvent.init();