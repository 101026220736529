'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

verallia.ArrayUtils = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: 'removeDuplicatesObjects',
        value: function removeDuplicatesObjects(tab) {
            tab = Array.from(new Set(tab.map(JSON.stringify))).map(JSON.parse);
            return tab;
        }
    }, {
        key: 'sortArray',
        value: function sortArray(fields, normal_order) {
            if (!normal_order && normal_order != false) normal_order = true;

            return function (a, b) {
                return fields.map(function (o) {
                    var dir = 1;

                    if (normal_order == true) {
                        if (o[0] === '-') {
                            //dir = -1
                            dir = 1;
                            o = o.substring(1);
                        }

                        if (a[o] > b[o]) return dir;
                        if (a[o] < b[o]) return -dir;
                        return 0;
                    } else {
                        if (o[0] === '-') {
                            dir = -1;
                            //dir = 1
                            o = o.substring(1);
                        }

                        if (a[o] > b[o]) return -dir;
                        if (a[o] < b[o]) return dir;
                        return 0;
                    }
                }).reduce(function firstNonZeroValue(p, n) {
                    return p || n;
                }, 0);
            };
        }
    }]);

    return _class;
}();