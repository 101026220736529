"use strict";

var VeralliaEvent = function VeralliaEvent() {
    this.ADDED = "added";
    this.REMOVED = "removed";
    this.RESIZE = "resize";
    this.START = "start";
    this.RESTART = "restart";
    this.APP_READY = "app_ready";
    this.READY = "ready";
    this.OPEN = "open";
    this.CLOSE = "close";
    this.DESTROY = "destroy";
    this.PAUSE = "pause";
    this.RESUME = "resume";
    this.STOP = "stop";
    this.END = "end";
    this.UPDATE = "update";
    this.FOCUS = "focus";
    this.BACK = "back";
    this.HASH_CHANGE = "hashchange";
    this.START_WORKING = "start_working";
    this.STOP_WORKING = "stop_working";
    this.DIALOG_OPEN = "dialog_open";
    this.DIALOG_CLOSE = "dialog_close";
    this.SECTION_CHANGE = "section_change";
};
verallia.Event = new VeralliaEvent();