"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

verallia.Pixi = function () {
    function _class() {
        _classCallCheck(this, _class);

        this.canvas;
        this.stage;
        this.renderer;
        this.stats;
        this.position;
        this.working;
    }

    _createClass(_class, null, [{
        key: "init",
        value: function init(width, height, params, auto_show, stats) {
            this.working = false;

            this.renderer = PIXI.autoDetectRenderer(width, height, params);

            //t.renderer = PIXI.CanvasRenderer(width, height, params);
            this.canvas = this.renderer.view;
            this.canvas.id = "pixi_canvas";
            $("body").append(this.canvas);

            this.stage = new PIXI.Container();

            if (stats == true) {
                this.stats = new Stats();
                $("body").append(this.stats.domElement);
                this.stats.domElement.id = "Debug";
            }

            if (auto_show == false) this.hide();
        }
    }, {
        key: "show",
        value: function show() {
            $("#Debug").show();
            $("#pixi_canvas").show();
        }
    }, {
        key: "hide",
        value: function hide() {
            $("#Debug").hide();
            $("#pixi_canvas").hide();
        }
    }, {
        key: "getLayer",
        value: function getLayer(id) {
            return this.layer[id];
        }
    }, {
        key: "startRender",
        value: function startRender() {
            this.working = true;
            this.render();
        }
    }, {
        key: "stopRender",
        value: function stopRender() {
            this.working = false;
        }
    }, {
        key: "render",
        value: function render() {
            if (this.working == false) return;

            this.renderer.render(this.stage);
            if (this.stats) this.stats.update();

            requestAnimationFrame(this.render.bind(this));
        }
    }]);

    return _class;
}();