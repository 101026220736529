"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

verallia.BrowserUtils = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: "isSafari",
        value: function isSafari() {
            return (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)
            );
        }
    }, {
        key: "setHTTPS",
        value: function setHTTPS() {
            if (window.location.href.indexOf("localhost") == -1 && window.location.protocol == "http:") {
                window.location.href = "https://" + location.host + location.pathname;
            }
        }
    }]);

    return _class;
}();