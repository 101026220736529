'use strict';

var verallia = verallia || {};

var Core = function Core() {
    this.dispatchEvent = function (type, target, data) {
        if (!target) target = window;

        $(target).trigger(type, data);
    };
    this.addObject = function (id, object) {
        if (!verallia.pool) verallia.pool = {};

        verallia.pool[id] = object;
    };
    this.getObject = function (id) {
        if (!verallia.pool) return null;

        return verallia.pool[id];
    };
    this.disposeObject = function (id) {
        verallia.pool[id] = null;
        delete verallia.pool[id];
    };
};
verallia.Core = new Core();

window.trace = function (text) {
    console.log(text);
};
$.urlParam = function (name) {
    var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
    if (results == null) {
        return null;
    } else {
        return results[1] || 0;
    }
};