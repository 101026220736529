'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var verallia = verallia || {};

verallia.WindowUtils = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: 'openURL',
        value: function openURL(url, target) {
            if (!target) target = "_self";
            window.open(url, target);
        }
    }, {
        key: 'setWindowTitle',
        value: function setWindowTitle(str) {
            document.title = str;
        }
    }, {
        key: 'isIE',
        value: function isIE() {
            var ua = window.navigator.userAgent,
                msie = ua.indexOf('MSIE '),
                // IE 10 or older
            trident = ua.indexOf('Trident/'),
                // IE 11
            edge = ua.indexOf('Edge/'); // Edge (IE 12+)
            return msie > 0 || trident > 0; // || (edge > 0);
        }
    }, {
        key: 'getUrlParam',
        value: function getUrlParam(name) {
            var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
            if (results == null) {
                return null;
            } else {
                return results[1] || 0;
            }
        }
    }, {
        key: 'setHash',
        value: function setHash(hash, keep_history) {
            //trace("keep_history: " + keep_history);
            if (keep_history == true) window.location.hash = hash;else window.history.replaceState(undefined, undefined, hash);
        }
    }]);

    return _class;
}();