'use strict';

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

verallia.SSOConfig = function () {
            function _class() {
                        _classCallCheck(this, _class);

                        this.DEV;
                        this.QUAL;
                        this.PROD;

                        this.initDev();
                        this.initProd();
                        this.initQual();
            }

            _createClass(_class, [{
                        key: 'initDev',
                        value: function initDev() {
                                    var bIsSafariMobile = window.device.mobile() == true && verallia.BrowserUtils.isSafari() == true;
                                    var sProtocol = location.protocol.slice(0, -1);
                                    var oDomain = {
                                                PROD: {
                                                            url: 'https://sso.corp.inpkg.net',
                                                            http: { id: 'verraliaOAuthClientAppsDEVHTTP', pwd: 'GbYyUJbl12Yj7f4qAyaG0R8SDwBCAPUR' },
                                                            https: { id: 'verraliaOAuthClientAppsDEV', pwd: 'GbYyUJbl12Yj7f4qAyaG0R8SDwBCAPUR' }
                                                },
                                                MOBILE: {
                                                            url: 'https://msso.corp.inpkg.net:444',
                                                            http: { id: 'verraliaOAuthClientAppsDEVHTTP', pwd: 'GbYyUJbl12Yj7f4qAyaG0R8SDwBCAPUR' },
                                                            https: { id: 'verraliaOAuthClientAppsDEV', pwd: 'GbYyUJbl12Yj7f4qAyaG0R8SDwBCAPUR' }
                                                },
                                                DEV: {
                                                            url: 'https://ssodev.corp.inpkg.net',
                                                            http: { id: 'verraliaOAuthClientAppsDEVHTTP', pwd: 'GbYyUJbl12Yj7f4qAyaG0R8SDwBCAPUR' },
                                                            https: { id: 'verraliaOAuthClientAppsDEV', pwd: 'GbYyUJbl12Yj7f4qAyaG0R8SDwBCAPUR' }
                                                }
                                    }[($.urlParam('cas') || (bIsSafariMobile ? 'MOBILE' : 'PROD')).toUpperCase()];

                                    this.DEV = {

                                                /** Base URI for authentication on CAS server **/
                                                BASE_URI: oDomain.url,

                                                /** Force it to CAS URL if CAS supports CORS - URI of reverse proxy to work around Same-Origin policy **/
                                                CAS_XDOMAIN_URI: oDomain.url,

                                                /** Client's ID for OAuth2 authentication on CAS server **/
                                                CAS_CLIENT_ID: oDomain[sProtocol].id,

                                                /** Client's secret for OAuth2 authentication on CAS server **/
                                                CAS_CLIENT_SECRET: oDomain[sProtocol].pwd,

                                                /** URI of CAS authorization page **/
                                                CAS_AUTH_URI: '/cas/oauth2.0/authorize',

                                                /** URI of CAS logout page **/
                                                CAS_LOGOUT_URI: '/cas/logout',

                                                /** URI of CAS access token page **/
                                                CAS_TOKEN_URI: '/cas/oauth2.0/accessToken',

                                                /** URI of CAS service to fetch logged user's profile **/
                                                CAS_USER_PROFILE_URI: '/cas/oauth2.0/profile',

                                                /** URI to pass in to CAS server for redirection after authorization **/
                                                CAS_REDIRECT_URI: encodeURI(location.href)
                                    };
                        }
            }, {
                        key: 'initProd',
                        value: function initProd() {
                                    //var bIsSafariMobile = sap.ui.Device.browser.mobile && sap.ui.Device.browser.name === sap.ui.Device.browser.BROWSER.SAFARI;
                                    var bIsSafariMobile = window.device.mobile() == true && verallia.BrowserUtils.isSafari() == true;
                                    var sProtocol = location.protocol.slice(0, -1);
                                    var oDomain = {
                                                PROD: {
                                                            url: 'https://sso.corp.inpkg.net',
                                                            http: { id: 'verraliaOAuthClientAppsPRODHTTP', pwd: 'hVOA2UwM8LwsGjO3QJnOxs6Nge6KcwX0' },
                                                            https: { id: 'verraliaOAuthClientAppsPROD', pwd: 'hVOA2UwM8LwsGjO3QJnOxs6Nge6KcwX0' }
                                                },
                                                MOBILE: {
                                                            url: 'https://msso.corp.inpkg.net:444',
                                                            http: { id: 'verraliaOAuthClientAppsPRODHTTP', pwd: 'hVOA2UwM8LwsGjO3QJnOxs6Nge6KcwX0' },
                                                            https: { id: 'verraliaOAuthClientAppsPROD', pwd: 'hVOA2UwM8LwsGjO3QJnOxs6Nge6KcwX0' }
                                                }
                                    }[bIsSafariMobile ? 'MOBILE' : 'PROD'];

                                    this.PROD = {

                                                /** Base URI for authentication on CAS server **/
                                                BASE_URI: oDomain.url,

                                                /** Force it to CAS URL if CAS supports CORS - URI of reverse proxy to work around Same-Origin policy **/
                                                CAS_XDOMAIN_URI: oDomain.url,

                                                /** Client's ID for OAuth2 authentication on CAS server **/
                                                CAS_CLIENT_ID: oDomain[sProtocol].id,

                                                /** Client's secret for OAuth2 authentication on CAS server **/
                                                CAS_CLIENT_SECRET: oDomain[sProtocol].pwd,

                                                /** URI of CAS authorization page **/
                                                CAS_AUTH_URI: '/cas/oauth2.0/authorize',

                                                /** URI of CAS logout page **/
                                                CAS_LOGOUT_URI: '/cas/logout',

                                                /** URI of CAS access token page **/
                                                CAS_TOKEN_URI: '/cas/oauth2.0/accessToken',

                                                /** URI of CAS service to fetch logged user's profile **/
                                                CAS_USER_PROFILE_URI: '/cas/oauth2.0/profile',

                                                /** URI to pass in to CAS server for redirection after authorization **/
                                                CAS_REDIRECT_URI: encodeURI(location.href)
                                    };
                        }
            }, {
                        key: 'initQual',
                        value: function initQual() {
                                    //var bIsSafariMobile = sap.ui.Device.browser.mobile && sap.ui.Device.browser.name === sap.ui.Device.browser.BROWSER.SAFARI;
                                    var bIsSafariMobile = window.device.mobile() == true && verallia.BrowserUtils.isSafari() == true;
                                    var sProtocol = location.protocol.slice(0, -1);
                                    var oDomain = {
                                                PROD: {
                                                            url: 'https://sso.corp.inpkg.net',
                                                            http: { id: 'verraliaOAuthClientAppsQUALHTTP', pwd: 'eLP9DcaZgZe0nrZGTSaqq3KWrbZcCYYW' },
                                                            https: { id: 'verraliaOAuthClientAppsQUAL', pwd: 'eLP9DcaZgZe0nrZGTSaqq3KWrbZcCYYW' }
                                                },
                                                MOBILE: {
                                                            url: 'https://msso.corp.inpkg.net:444',
                                                            http: { id: 'verraliaOAuthClientAppsQUALHTTP', pwd: 'eLP9DcaZgZe0nrZGTSaqq3KWrbZcCYYW' },
                                                            https: { id: 'verraliaOAuthClientAppsQUAL', pwd: 'eLP9DcaZgZe0nrZGTSaqq3KWrbZcCYYW' }
                                                },
                                                DEV: {
                                                            url: 'https://ssodev.corp.inpkg.net',
                                                            http: { id: 'verraliaOAuthClientAppsQUALHTTP', pwd: 'eLP9DcaZgZe0nrZGTSaqq3KWrbZcCYYW' },
                                                            https: { id: 'verraliaOAuthClientAppsQUAL', pwd: 'eLP9DcaZgZe0nrZGTSaqq3KWrbZcCYYW' }
                                                }
                                    }[($.urlParam('cas') || (bIsSafariMobile ? 'MOBILE' : 'PROD')).toUpperCase()];

                                    this.QUAL = {

                                                /** Base URI for authentication on CAS server **/
                                                BASE_URI: oDomain.url,

                                                /** Force it to CAS URL if CAS supports CORS - URI of reverse proxy to work around Same-Origin policy **/
                                                CAS_XDOMAIN_URI: oDomain.url,

                                                /** Client's ID for OAuth2 authentication on CAS server **/
                                                CAS_CLIENT_ID: oDomain[sProtocol].id,

                                                /** Client's secret for OAuth2 authentication on CAS server **/
                                                CAS_CLIENT_SECRET: oDomain[sProtocol].pwd,

                                                /** URI of CAS authorization page **/
                                                CAS_AUTH_URI: '/cas/oauth2.0/authorize',

                                                /** URI of CAS logout page **/
                                                CAS_LOGOUT_URI: '/cas/logout',

                                                /** URI of CAS access token page **/
                                                CAS_TOKEN_URI: '/cas/oauth2.0/accessToken',

                                                /** URI of CAS service to fetch logged user's profile **/
                                                CAS_USER_PROFILE_URI: '/cas/oauth2.0/profile',

                                                /** URI to pass in to CAS server for redirection after authorization **/
                                                CAS_REDIRECT_URI: encodeURI(location.href)
                                    };
                        }
            }]);

            return _class;
}();