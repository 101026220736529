"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

verallia.CordovaUtils = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: "isCordova",
        value: function isCordova() {
            if (window.cordova) {
                return true;
            } else {
                return false;
            }
        }
    }, {
        key: "cordovaVersion",
        value: function cordovaVersion() {
            return "1.0";
        }

        /****************************************************** */
        /**********   FILES UTILS
        /****************************************************** */

    }, {
        key: "scan",
        value: function scan(path, show_files_data, callback) {
            var t = this;

            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, function (dir) {
                dir.getDirectory(path, { create: true }, function (directory) {
                    var directoryReader = directory.createReader();

                    directoryReader.readEntries(function (entries) {
                        var entry_index = 0;
                        var data = { _root_files: [] };

                        checkNextEntry();

                        function checkNextEntry() {
                            if (entry_index == entries.length) {
                                callback(data);
                            } else {
                                var entry = entries[entry_index];

                                if (entry.isDirectory == true) {
                                    if (typeof entry.name == "string" && entry.name != "." && entry.name != ".." && entry.name != "[object Object]") {
                                        t.scan(path + entry.name + "/", show_files_data, entryCheckComplete);
                                    } else {
                                        entry_index++;
                                        checkNextEntry();
                                    }
                                } else if (entry.isFile == true) {
                                    entry.getMetadata(entryMetadataCheckComplete, function (error) {
                                        trace(error);
                                    });
                                }
                            }
                        }

                        function entryCheckComplete(entries_data) {
                            var entry = entries[entry_index];
                            data[entry.name] = entries_data;

                            entry_index++;
                            checkNextEntry();
                        }
                        function entryMetadataCheckComplete(metadata) {
                            var entry = entries[entry_index];
                            if (show_files_data == false) data._root_files.push(entry.name);else data._root_files.push({ filename: entry.name, last_modified: metadata.modificationTime.getTime() / 1000, size: metadata.size });

                            entry_index++;
                            checkNextEntry();
                        }
                    }, function (error) {
                        trace(error.code);
                    });
                }, function () {});
            });
        }
    }, {
        key: "makePath",
        value: function makePath(path, callback) {
            var sep = "/";

            path = path.replace(cordova.file.dataDirectory, "");
            var segments = path.split(sep);
            var current = "";
            var i = 0;

            var t = this;
            makeNextDir();

            function makeNextDir() {
                if (i == segments.length) {
                    if (callback) callback();
                    return;
                }

                current += segments[i] + sep;
                i++;
                t.makeDir(current, makeNextDir);
            }
        }
    }, {
        key: "makeDir",
        value: function makeDir(path, callback) {
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, function (dir) {
                dir.getDirectory(path, { create: true }, function (directory) {
                    if (callback) callback();
                }, function () {
                    if (callback) callback("error");
                });
            });
        }
    }, {
        key: "writeFile",
        value: function writeFile(filepath, data, callback) {
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, function (dir) {
                dir.getFile(filepath, { create: true }, function (file) {

                    file.createWriter(function (fileWriter) {

                        fileWriter.onwriteend = function () {
                            if (callback) callback();
                        };
                        fileWriter.onerror = function (e) {
                            if (callback) callback(e);
                        };
                        fileWriter.write(data);
                    });
                });
            });
        }
    }, {
        key: "deleteFile",
        value: function deleteFile(filepath, callback) {
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, function (dir) {
                dir.getFile(filepath, { create: false }, function (file) {
                    file.remove(function () {
                        if (callback) callback();
                    }, function (error) {
                        if (callback) callback();
                    }, function () {
                        if (callback) callback();
                    });
                });
            });
        }
    }, {
        key: "deletePath",
        value: function deletePath(path, callback) {
            window.resolveLocalFileSystemURL(cordova.file.dataDirectory, function (dir) {
                dir.getDirectory(filepath, { create: false }, function (directory) {
                    directory.removeRecursively(function () {});
                });
            });
        }
    }, {
        key: "copyFile",
        value: function copyFile(filpath, dest_filepath, callback) {
            window.resolveLocalFileSystemURL(filpath, function (file) {
                window.requestFileSystem(window.PERSISTENT, 5 * 1024 * 1024, function (fileSystem) {
                    var documentsPath = fileSystem.root;
                    console.log(documentsPath);
                    file.copyTo(documentsPath, dest_filepath, function (res) {
                        callback();
                    }, function () {
                        callback("error in file copy");
                    });
                });
            }, function () {
                callback("error file not found");
            });
        }
    }]);

    return _class;
}();