"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

verallia.SyncUtils = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: "getSyncDataFromFilesLists",
        value: function getSyncDataFromFilesLists(local_files, distant_files, wanted_files) {
            var data = { total_bytes: 0, loaded_bytes: 0, downloads: [], delete: [] };
            var filepath_list = [];

            if (wanted_files) {
                //this.updateWantedFromLocalFiles(local_files, wanted_files, ""); // update des dates et tailles de fichiers des fichiers demandés depuis les fichiers déjà existants localement (obsolète car dans la fonction suivante on ajoute les fichiers manquants uniquement)
                this.mergeWantedAndLocalFiles(local_files, wanted_files, ""); // ajout des fichiers demandés dans les fichiers locaux
                this.checkDownloadsFromFilesList(data, local_files, distant_files, "", true, filepath_list); // créé la liste des fichiers à télécharger en fonction des fichiers locaux
                this.checkDeletesFromFilesList(data, local_files, distant_files, "", true); // créé la liste des fichiers à effacer en fonction des fichiers distants (supprime les fichiers non demandés et présents dans la liste locale)
            } else {
                this.checkDownloadsFromFilesList(data, local_files, distant_files, "", false, filepath_list); // créé la liste des fichiers à télécharger en fonction des fichiers distants
                this.checkDeletesFromFilesList(data, local_files, distant_files, "", false); // créé la liste des fichiers à effacer en fonction des fichiers distants
            }

            //REMOVE DUPLICATES
            data.downloads = verallia.ArrayUtils.removeDuplicatesObjects(data.downloads, "filename");
            data.delete = verallia.ArrayUtils.removeDuplicatesObjects(data.delete, "filename");

            return data;
        }
    }, {
        key: "mergeWantedAndLocalFiles",
        value: function mergeWantedAndLocalFiles(local, wanted, dir) {
            for (var str in wanted) {
                if (str != "_root_files") {
                    if (!local[str]) local[str] = { _root_files: [] };

                    this.mergeWantedAndLocalFiles(local[str], wanted[str], dir + str + "/");
                } else {
                    var a = wanted._root_files.length;
                    for (var i = 0; i < a; i++) {
                        var wanted_file = wanted._root_files[i];
                        var file_exists = false;

                        var b = local._root_files.length;
                        for (var j = 0; j < b; j++) {
                            var local_file = local._root_files[j];

                            if (local_file.filename == wanted_file.filename) {
                                file_exists = true;
                                local_file.wanted = true;
                            }
                        }

                        if (file_exists == false) {
                            local._root_files.push(wanted_file);
                        }
                    }
                }
            }
        }
    }, {
        key: "updateWantedFromLocalFiles",
        value: function updateWantedFromLocalFiles(local, wanted, dir) {
            for (var str in wanted) {
                if (str != "_root_files") {
                    var local_dir = local;
                    if (local_dir) local_dir = local_dir[str];
                    this.updateWantedFromLocalFiles(local_dir, wanted[str], dir + str + "/");
                } else {
                    if (local && local._root_files) {
                        var a = local._root_files.length;
                        for (var i = 0; i < a; i++) {
                            var local_file = local._root_files[i];

                            var b = wanted._root_files.length;
                            for (var j = 0; j < b; j++) {
                                var wanted_file = wanted._root_files[j];

                                if (local_file.filename == wanted_file.filename) {
                                    wanted_file.size = local_file.size;
                                    wanted_file.last_modified = local_file.last_modified;
                                }
                            }
                        }
                    }
                }
            }
        }
    }, {
        key: "checkDownloadsFromFilesList",
        value: function checkDownloadsFromFilesList(data, local, distant, dir, only_wanted_files, filepath_list) {
            for (var str in distant) {
                if (str != "_root_files") {
                    var local_dir = local;
                    if (local_dir) local_dir = local_dir[str];
                    this.checkDownloadsFromFilesList(data, local_dir, distant[str], dir + str + "/", only_wanted_files, filepath_list);
                } else {
                    if (!local || !local._root_files) {
                        if (only_wanted_files == false) {
                            var a = distant._root_files.length;
                            for (var i = 0; i < a; i++) {
                                if (filepath_list.indexOf(dir + distant._root_files[i].filename) == -1) {
                                    data.downloads.push({ filepath: dir + distant._root_files[i].filename, size: distant._root_files[i].size });
                                    data.total_bytes += distant._root_files[i].size;
                                    filepath_list.push(dir + distant._root_files[i].filename);
                                }
                            }
                        }
                    } else {
                        var distant_file;
                        var local_file;
                        var a = distant._root_files.length;
                        for (var i = 0; i < a; i++) {
                            distant_file = distant._root_files[i];

                            var file_match = false;

                            var b = local._root_files.length;
                            for (var j = 0; j < b; j++) {
                                local_file = local._root_files[j];

                                //if(only_wanted_files == true) trace(local_file.filename + " : " + distant_file.filename);

                                if (local_file.filename == distant_file.filename) {
                                    file_match = true;
                                    if (local_file.last_modified < distant_file.last_modified) {
                                        if (filepath_list.indexOf(dir + distant._root_files[i].filename) == -1) {
                                            data.downloads.push({ filepath: dir + distant_file.filename, size: distant_file.size });
                                            data.total_bytes += distant_file.size;
                                            filepath_list.push(dir + distant._root_files[i].filename);
                                        }
                                    }
                                }
                            }

                            if (file_match == false && only_wanted_files == false) {
                                if (filepath_list.indexOf(dir + distant._root_files[i].filename) == -1) {
                                    data.downloads.push({ filepath: dir + distant._root_files[i].filename, size: distant_file.size });
                                    data.total_bytes += distant_file.size;
                                    filepath_list.push(dir + distant._root_files[i].filename);
                                }
                            }
                        }
                    }
                }
            }
        }
    }, {
        key: "checkDeletesFromFilesList",
        value: function checkDeletesFromFilesList(data, local, distant, dir, only_wanted_files) {
            for (var str in local) {
                if (str != "_root_files") {
                    var distant_dir = distant;

                    if (distant_dir) distant_dir = distant_dir[str];
                    this.checkDeletesFromFilesList(data, local[str], distant[str], dir + str + "/", only_wanted_files);
                } else {
                    if (!distant || !distant._root_files) {
                        var a = local._root_files.length;
                        for (var i = 0; i < a; i++) {
                            data.delete.push({ filepath: dir + local._root_files[i].filename, size: local._root_files[i].size });
                        }
                    } else {
                        var distant_file;
                        var local_file;
                        var a = local._root_files.length;
                        for (var i = 0; i < a; i++) {
                            local_file = local._root_files[i];

                            var file_match = false;

                            var b = distant._root_files.length;
                            for (var j = 0; j < b; j++) {
                                distant_file = distant._root_files[j];

                                if (local_file.filename == distant_file.filename) {
                                    file_match = true;
                                }
                            }

                            if (file_match == false) {
                                data.delete.push({ filepath: dir + local_file.filename, size: local_file.size });
                            } else if (only_wanted_files == true && !local_file.wanted) {
                                //trace("deleting " + dir + local_file.filename);
                                data.delete.push({ filepath: dir + local_file.filename, size: local_file.size });
                            }
                        }
                    }
                }
            }
        }
    }]);

    return _class;
}();