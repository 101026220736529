"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

verallia.StringUtils = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: "generateUID",
        value: function generateUID() {
            var l = 10;
            //if(length) l = length;
            var uid = "";
            var chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < l; i++) {
                uid += chars.charAt(Math.floor(Math.random() * chars.length));
            }

            return uid;
        }
    }, {
        key: "replace",
        value: function replace(text, expression, replace_expression, all) {
            if (!all) all = "all";
            if (all == "all") return text.replace(new RegExp(expression, "g"), replace_expression);else if (all == "once") return text.replace(expression, replace_expression);
            //return(text.replace(/expression/g, replace_expression));
        }
    }, {
        key: "makeURLString",
        value: function makeURLString(str) {
            return str.replace(/[^a-zA-Z0-9]/g, '');
        }
    }, {
        key: "decodeEntities",
        value: function decodeEntities(encodedString) {
            return decodeURI(encodedString);
        }
    }, {
        key: "isString",
        value: function isString(value) {
            if (!value || value == "") return false;

            if (typeof value === 'string' || value instanceof String) return true;else return false;
        }
    }]);

    return _class;
}();