"use strict";

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) { return typeof obj; } : function (obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

verallia.NodeUtils = function () {
    function _class() {
        _classCallCheck(this, _class);

        this.nw_gui;
        this.fs;
        this.path;
        this.request;
        this.http;
        this.ncp;
        this.proxy = "http://internet-proxy.net.inpkg.net";
        this.paused = false;
    }

    _createClass(_class, null, [{
        key: "isNode",
        value: function isNode() {
            if ((typeof process === "undefined" ? "undefined" : _typeof(process)) == "object") {
                if (!this.nw_gui) this.nw_gui = require('nw.gui');
                if (!this.fs) this.fs = require('fs');
                if (!this.path) this.path = require('path');
                if (!this.request) this.request = require('request');
                if (!this.http) this.http = require('http');
                if (!this.ncp) this.ncp = require('ncp').ncp;
                return true;
            } else {
                return false;
            }
        }
    }, {
        key: "nodeVersion",
        value: function nodeVersion() {
            if (this.isNode() == true) return process.versions['node-webkit'];
        }
    }, {
        key: "openDebugger",
        value: function openDebugger() {
            if (this.isNode() == false) return;

            this.nw_gui.Window.get().showDevTools();

            setTimeout(this.focusOnWindow.bind(this), 1000);
            setTimeout(this.focusOnWindow.bind(this), 1500);
            setTimeout(this.focusOnWindow.bind(this), 2000);
            setTimeout(this.focusOnWindow.bind(this), 2500);
            setTimeout(this.focusOnWindow.bind(this), 3000);
            setTimeout(this.focusOnWindow.bind(this), 3500);
            setTimeout(this.focusOnWindow.bind(this), 4000);
        }
    }, {
        key: "focusOnWindow",
        value: function focusOnWindow() {
            this.nw_gui.Window.get().focus();
        }
    }, {
        key: "setWindowPosition",
        value: function setWindowPosition(x, y) {
            if (this.isNode() == false) return;

            this.nw_gui.Window.get().moveTo(x, y);
        }

        /****************************************************** */
        /**********   FILES UTILS
        /****************************************************** */

    }, {
        key: "scan",
        value: function scan(path, show_files_data, callback) {
            var data = this.scanDir(path, show_files_data);

            callback(data);
        }
    }, {
        key: "scanDir",
        value: function scanDir(filename, show_files_data) {
            var path = require('path');

            var data = { _root_files: [] };

            if (!this.fs.existsSync(filename)) return data;

            var stats = this.fs.lstatSync(filename);

            var t = this;

            if (stats.isDirectory()) {
                var files = this.fs.readdirSync(filename);
                for (var i in files) {
                    stats = this.fs.lstatSync(filename + "/" + files[i]);

                    if (stats.isDirectory()) {
                        data[files[i]] = t.scanDir(filename + "/" + files[i], show_files_data);
                    } else {
                        if (show_files_data == true) data._root_files.push({ filename: files[i], last_modified: Math.round(new Date(stats.mtime).getTime() / 1000), size: stats.size });else data._root_files.push(files[i]);
                    }
                }
            } else {

                if (show_files_data == true) data._root_files.push({ filename: filename, last_modified: Math.round(new Date(stats.mtime).getTime() / 1000), size: stats.size });else data._root_files.push(filename);
            }

            return data;
        }
    }, {
        key: "unzip",
        value: function unzip(zip_path, extract_path, callback) {
            var unzip = require("unzip");

            if (callback) this.fs.createReadStream(zip_path).pipe(unzip.Extract({ path: extract_path })).on('close', callback);else this.fs.createReadStream(zip_path).pipe(unzip.Extract({ path: extract_path }));
        }
    }, {
        key: "makePath",
        value: function makePath(path, callback) {
            var sep = "/";

            var segments = path.split(sep);
            var current = "";
            var i = 0;

            var t = this;
            makeNextDir();

            function makeNextDir() {
                if (i >= segments.length) {
                    if (callback) callback();
                    return;
                }

                current += segments[i] + sep;
                i++;
                t.makeDir(current, makeNextDir);
            }
        }
    }, {
        key: "downloadAndWrite",
        value: function downloadAndWrite(url, filepath, callback) {
            var t = this;

            if (filepath.split("/").length > 1) {
                var tab = filepath.split("/");
                tab.splice(tab.length - 1, 1);
                this.makePath(tab.join("/"), relaunchDownload);
            }

            function relaunchDownload() {
                t.startDownloadAndWrite(url, filepath, callback);
            }
        }
    }, {
        key: "startDownloadAndWrite",
        value: function startDownloadAndWrite(url, filepath, callback) {
            var t = this;

            var file = this.fs.createWriteStream(filepath, { encoding: 'binary' });
            var request = this.request({ url: url, encoding: 'binary' }, function (response) {

                file.on('finish', function () {
                    if (callback) file.close(callback);else file.close();
                });
                file.on('error', function (err) {
                    t.fs.unlink(filepath);
                    if (callback) callback(err.message);
                });
            });

            request.pipe(file);
        }
    }, {
        key: "writeFile",
        value: function writeFile(filepath, data, callback) {
            if (callback) this.fs.writeFile(filepath, data, callback);else this.fs.writeFile(filepath, data);
        }
    }, {
        key: "deleteFile",
        value: function deleteFile(filepath, callback) {
            if (callback) this.fs.unlink(filepath, callback);else this.fs.unlink(filepath);
        }
    }, {
        key: "makeDir",
        value: function makeDir(dir, callback) {
            if (callback) this.fs.mkdir(dir, callback);else this.fs.mkdir(dir);
        }
    }, {
        key: "deletePath",
        value: function deletePath(path, callback) {
            if (this.fs.existsSync(path)) {
                var t = this;

                this.fs.readdirSync(path).forEach(function (file, index) {
                    var curPath = path + "/" + file;
                    if (t.fs.lstatSync(curPath).isDirectory()) {
                        t.deletePath(curPath);
                    } else {
                        t.fs.unlinkSync(curPath);
                    }
                });

                this.fs.rmdirSync(path);
            }

            if (callback) callback();
        }
    }, {
        key: "copyPath",
        value: function copyPath(path, from, callback) {
            this.ncp(path, from, function (err) {
                if (err) {
                    if (callback) callback(err);
                    return;
                }
                if (callback) callback();
            });
        }
    }, {
        key: "copyFile",
        value: function copyFile(filepath, dest_filepath, callback) {
            this.fs.copyFile(filepath, dest_filepath, function (err) {
                if (err) throw err;

                callback(err);
            });
        }
    }]);

    return _class;
}();