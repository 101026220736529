"use strict";

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

verallia.FileUtils = function () {
    function _class() {
        _classCallCheck(this, _class);
    }

    _createClass(_class, null, [{
        key: "createFile",
        value: function createFile(data, filename) {
            var extension = this.getFileExtensions(filename);
            var type;

            if (extension == "txt") type = "text/plain";else if (extension == "json") type = "application/json";else if (extension == "jpg" || extension == "jpeg") type = "image/jpg";else if (extension == "gif") type = "image/gif";else if (extension == "png") type = "image/png";

            return new File([data], filename, { lastModified: verallia.DateUtils.getTimestamp(), type: type });
        }
    }, {
        key: "getFileExtensions",
        value: function getFileExtensions(filename) {
            return filename.split('.').pop();
        }
    }, {
        key: "convertDataURIToBinary",
        value: function convertDataURIToBinary(dataURI) {
            var BASE64_MARKER = ';base64,';
            var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
            var base64 = dataURI.substring(base64Index);
            var raw = window.atob(base64);
            var rawLength = raw.length;
            var array = new Uint8Array(new ArrayBuffer(rawLength));

            for (var i = 0; i < rawLength; i++) {
                array[i] = raw.charCodeAt(i);
            }
            return array;
        }
    }]);

    return _class;
}();